<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-if="loaded"
        :headers="tableHeaders"
        :items="sowingWithoutHarvest"
        :options="tableOptions"
        :footer-props="tableFooterProps"
        :hide-default-footer="hasFooter"
        :sort-desc.sync="sortDesc"
      >
        <template #header.estadio_atual_data_ideal_proxima_aplicacao>
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <span class="mr-2">{{ $t('stage.stage') }}</span>
            </div>
            <div class="d-flex align-center">
              <span>{{ $t('Home.sowing.next_spray') }}</span>
            </div>
          </div>
        </template>

        <template #item.talhao="{ item }">
          <div>
            <a v-if="isInCurrentSeasons(item)" @click="goToSemeadura(item.id)">
              {{ item.talhao }} - {{ item.cultivar }}
            </a>
            <df-tooltip v-else :text="$t('Home.sowing.not_current_season')">
              <span> {{ item.talhao }} - {{ item.cultivar }} </span>
            </df-tooltip>
          </div>
          <div class="caption">
            {{ item.data_semeadura.formatDate() }}
          </div>
        </template>

        <template #item.estadio_atual_data_ideal_proxima_aplicacao="{ item }">
          <v-btn
            v-if="hasPermission($p.harvest.create) && item.ready_to_harvest"
            outlined
            text
            class="black--text text-none button-register-harvest"
            @click.stop="openModalHarvest(item)"
          >
            <span class="text-register-harvest">
              {{ $t('harvest.register_harvest') }}
            </span>
            <font-awesome-icon
              class="pl-2"
              color="#39AF49"
              icon="seedling"
              size="lg"
            />
          </v-btn>

          <v-chip
            color="#D8F2DC"
            v-else-if="
              !hasPermission($p.harvest.create) && item.ready_to_harvest
            "
          >
            <font-awesome-icon
              class="fa-07"
              color="#39AF49"
              icon="seedling"
              size="sm"
            />
            <span class="ready_to_harvest_whithout_permission pl-1">
              {{ $t('harvest.ready_to_harvest') }}
            </span>
          </v-chip>

          <div v-else class="d-flex justify-space-between px-4">
            <div class="d-flex align-center">
              {{ item.estadio_atual.name }}
            </div>

            <div v-if="hideNextSprayDate(item)" class="pr-7">-</div>

            <div v-else-if="item.data_ideal_proxima_aplicacao">
              {{ item.data_ideal_proxima_aplicacao.formatDate() }}
            </div>
          </div>
        </template>
      </v-data-table>
      <div v-else class="d-flex justify-center">
        <v-progress-circular indeterminate color="#4CAF50" />
      </div>
      <modal-colheita
        v-if="openHarvestModal && semeadura"
        :dialog="openHarvestModal"
        :semeadura="semeadura"
        @close="refresh"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ModalColheita from '@/pages/execucao/ModalColheita.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import '@/lib/components/Tooltip/DfTooltip.js'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'
import { isSowingInCurrentSeasons } from '@/utils/seasonsCrops'

const ITEMS_PER_PAGE = 10

export default {
  name: 'CardSemeaduras',

  mixins: [PermissionMixin],

  components: {
    ModalColheita,
  },

  data() {
    return {
      semeadura: {},
      loaded: false,
      sortDesc: false,
      tableOptions: {
        itemsPerPage: ITEMS_PER_PAGE,
      },
      openHarvestModal: false,
      tableFooterProps: {
        'items-per-page-options': [ITEMS_PER_PAGE],
        'disable-items-per-page': true,
      },
      tableHeaders: [
        {
          text: this.$t('semeadura.semeadura'),
          value: 'talhao',
          align: 'start',
          sortable: false,
        },
        {
          text: '',
          value: 'estadio_atual_data_ideal_proxima_aplicacao',
          width: 220,
          sortable: false,
        },
        {
          text: this.$t('aplicacao.aplicacoes'),
          value: 'aplicacao_atual_total',
          align: 'center',
          width: 80,
          sortable: false,
        },
      ],
    }
  },

  props: {
    semeaduras: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 4000)
  },

  watch: {
    semeaduras(semeaduras) {
      let of = this.$t('of')
      return semeaduras.map(function (semeadura) {
        semeadura.aplicacao_atual_total = semeadura.total_execucoes
          ? `${semeadura.total_aplicacoes} ${of} ${semeadura.total_execucoes}`
          : '-'

        semeadura.hasNoAplicacao = semeadura.total_execucoes === 0

        // XXX: coloca garante que aplicações completas vão para o fim da lista
        if (semeadura.colheita)
          semeadura.data_ideal_proxima_aplicacao = 9999999999
      })
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    hasFooter() {
      return this.semeaduras.length <= ITEMS_PER_PAGE
    },
    sowingWithoutHarvest() {
      if (!this.semeaduras || !this.semeaduras.length) {
        return []
      }

      return this.semeaduras
    },
  },

  methods: {
    goToSemeadura(semeaduraId) {
      this.$router.push({
        path: '/execucao',
        query: {
          semeadura_id: semeaduraId,
          fazenda_id: this.currentFarmId,
        },
      })
    },
    hideNextSprayDate(semeadura) {
      return semeadura.hasNoAplicacao || semeadura.colheita
    },
    isInCurrentSeasons(sowing) {
      return isSowingInCurrentSeasons(sowing, this.seasonsCrops)
    },
    openModalHarvest(planting) {
      logEvent(events.harvests.clickedButton_registerSingleHarvest)
      this.openHarvestModal = true
      this.semeadura = planting
    },
    refresh() {
      this.openHarvestModal = false
      this.$router.go()
    },
  },
}
</script>

<style lang="scss" scoped>
// data-table
.v-sheet.v-card {
  box-shadow: none;
  border: 1px solid $color-border;
  border-radius: $border-radius-sm;
}
.v-data-footer__select {
  visibility: hidden !important;
}
.text-register-harvest {
  @include label-small;
}
.ready_to_harvest_whithout_permission {
  @include label-x-small;
  color: $color-text-primary;
  text-transform: uppercase;
}
</style>
