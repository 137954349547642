<template>
  <div class="card-weather" :class="{ 'has-border': border }">
    <div v-show="loadingWeather" class="df-flex-m df-flex-col loading__wrapper">
      <df-skeleton-loader height="140px" />
      <hr />
      <df-skeleton-loader height="185px" />
    </div>
    <without-weather v-show="shouldShowWithoutWeather" />
    <div v-show="shouldShowContent" class="df-flex-l df-flex-col">
      <div class="df-flex-none align-center justify-space-between">
        <span class="card__title">
          {{ $t('weather.info') }}
        </span>
        <df-button
          icon="edit"
          variant="text-blue"
          @click="openPrecipitationModal"
        >
          {{ $t('Weather.adjust') }}
        </df-button>
      </div>
      <card-weather-condition :weather="weather" />
      <df-button
        icon="clock"
        color-icon="c3d1e9"
        variant="text-blue"
        @click="openPrecipitationMetereologyModal"
      >
        {{ $t('Weather.precipitation_hour') }}
      </df-button>
      <precipitation-metereology-modal
        v-if="isMetereologyModalOpened && weather.length"
        :key="$currentLanguage()"
        :weather="weather"
        @close="closePrecipitationMetereologyModal"
      />
    </div>
    <precipitation-modal
      v-if="isPrecipitationModalOpened"
      @close="closePrecipitationModal"
      @update-weather="updateWeather"
    />
  </div>
</template>

<script>
import CardWeatherCondition from '@/components/Weather/CardWeatherCondition.vue'
import DfButton from '@/lib/components/Button/DfButton.vue'
import WithoutWeather from '@/components/Banners/WithoutWeather.vue'
import { isDevMode } from '@/utils/envConfigs'
import { mapGetters } from 'vuex'
const PrecipitationMetereologyModal = () =>
  import('@/components/Weather/PrecipitationMetereologyModal.vue')
const PrecipitationModal = () =>
  import('@/components/Weather/PrecipitationModal.vue')
import '@/lib/components/Loader/DfSkeletonLoader.js'

export default {
  name: 'CardWeather',

  components: {
    CardWeatherCondition,
    DfButton,
    PrecipitationMetereologyModal,
    PrecipitationModal,
    WithoutWeather,
  },

  props: {
    border: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isPrecipitationModalOpened: false,
      isMetereologyModalOpened: false,
      loadingWeather: false,
      weather: [],
    }
  },

  watch: {
    currentFarmId: {
      async handler(id) {
        if (id) {
          await this.fetchPrecipitation(id)
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId', 'enabledFarm']),
    shouldShowContent() {
      return !this.loadingWeather && this.weather.length && this.enabledFarm
    },
    shouldShowWithoutWeather() {
      return !this.loadingWeather && (!this.weather.length || !this.enabledFarm)
    },
  },

  methods: {
    closePrecipitationMetereologyModal() {
      this.isMetereologyModalOpened = false
    },
    closePrecipitationModal() {
      this.isPrecipitationModalOpened = false
    },
    async fetchPrecipitation(id) {
      // Endpoint doesn't work locally yet, it's paid
      if (!isDevMode || process.env.VUE_APP_MOCK_MIRAGE) {
        this.loadingWeather = true
        try {
          const { data } = await this.$api.fazendas.precipitation(id)
          if (data) {
            this.weather = data
          }
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingWeather = false
        }
      }
    },
    openPrecipitationModal() {
      this.isPrecipitationModalOpened = true
    },
    openPrecipitationMetereologyModal() {
      this.isMetereologyModalOpened = true
    },
    async updateWeather() {
      await this.fetchPrecipitation(this.currentFarmId)
      this.closePrecipitationModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.card-weather {
  background-color: $color-background-white;
  border-radius: $spacing-xs;
  border: none;
  padding: 0px;

  &.has-border {
    border: 1px solid $color-border;
    padding: $spacing-l;
  }
  .loading__wrapper {
    hr {
      border: 0;
      height: 1px;
      opacity: 0.2;
      border-bottom: 1px dashed $black;
    }
  }
  .card__title {
    @include heading-x-small;
    color: $color-text-disabled;
    font-size: 12px;
    text-transform: uppercase;
  }
}
</style>
