<template>
  <div class="quotation-card">
    <v-tabs v-model="tab" flat background-color="transparent" grow>
      <v-tab v-for="item in items" :key="item.label">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <df-skeleton-loader v-show="loading || loadingFarms" height="196px" />
    <disabled-quotation-card
      v-show="!loading && !loadingFarms && !enabledFarm"
    />
    <v-tabs-items
      v-model="tab"
      v-show="!loading && !loadingFarms && enabledFarm"
    >
      <v-tab-item v-for="item in items" :key="item.label">
        <component
          :is="item.component"
          @loading-brazil-chicago="setLoading"
          @loading-ordinaces="setLoading"
          @loading-physical-market="setLoading"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BrazilChicagoCard from '@/components/MarketQuotations/BrazilChicagoCard.vue'
import ChicagoCard from '@/components/MarketQuotations/ChicagoCard.vue'
import DisabledQuotationCard from '@/components/MarketQuotations/DisabledQuotationCard.vue'
import PhysicalMarketCard from '@/components/MarketQuotations/PhysicalMarketCard.vue'
import { mapGetters } from 'vuex'
import '@/lib/components/Loader/DfSkeletonLoader.js'

export default {
  name: 'QuotationCard',

  data() {
    return {
      items: [],
      loading: false,
      tab: null,
    }
  },

  components: {
    BrazilChicagoCard,
    ChicagoCard,
    DisabledQuotationCard,
    PhysicalMarketCard,
  },

  computed: {
    ...mapGetters('farms', ['enabledFarm', 'loadingFarms']),
    ...mapGetters('user', ['isBrazil']),
  },

  mounted() {
    if (this.isBrazil) {
      this.items = [
        {
          label: this.$t('physical_market'),
          component: PhysicalMarketCard,
        },
        {
          label: this.$t('stock_chicago'),
          component: BrazilChicagoCard,
        },
      ]
    } else {
      this.items = [
        {
          label: this.$t('stock_chicago'),
          component: ChicagoCard,
        },
      ]
    }
  },

  methods: {
    setLoading(loading) {
      this.loading = loading
    },
  },
}
</script>

<style lang="scss" scoped>
.quotation-card {
  border: 1px solid $color-border;
  border-radius: $border-radius-sm;
}
.v-tabs {
  margin-bottom: 0px !important;
}
::v-deep .v-tab {
  background-color: $color-background-light;
}
::v-deep .v-tabs-items {
  border-radius: 0px 0px $border-radius-sm $border-radius-sm;
}
::v-deep .v-tabs-slider {
  height: 0px !important;
}
::v-deep .v-tabs--grow > .v-tabs-bar .v-tab {
  @include label-x-small;
  color: $color-text-neutral;
  font-weight: 600;
  text-transform: uppercase !important;
}
::v-deep .v-tab--active {
  background-color: $color-background-white !important;
  border-radius: $border-radius-sm $border-radius-sm 0px 0px;
}
::v-deep .v-tabs-bar {
  z-index: 1;
}
</style>
