<template>
  <div
    class="df-flex-none df-flex-col item-card"
    :class="disabledClass"
    @click="redirectToSowingPage"
  >
    <section class="df-flex-l df-flex-col item-card__content">
      <section class="df-flex-none justify-space-between align-start">
        <h1>{{ title }}</h1>
        <v-menu offset-x>
          <template #activator="{ on }">
            <v-btn icon height="24px" width="24px" v-on="on">
              <font-awesome-icon icon="ellipsis-v" :color="colorIconNeutral" />
            </v-btn>
          </template>
          <v-card>
            <v-card-actions
              class="df-flex-none flex-column align-start content__actions pa-0"
            >
              <v-tooltip
                top
                :color="colorBackgroundBlack"
                :disabled="isInCurrentSeasons"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="actions__edit"
                    plain
                    text
                    :disabled="!isInCurrentSeasons"
                    @click="redirectToSowingPage"
                  >
                    <span v-on="on" v-bind="attrs">
                      {{ $t('editar') }}
                    </span>
                  </v-btn>
                </template>
                <span>
                  {{ $t('Home.sowing.not_current_season') }}
                </span>
              </v-tooltip>
              <v-btn
                class="actions__exclude"
                plain
                text
                @click="openDeleteModal"
              >
                {{ $t('excluir') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </section>
      <section class="df-flex-sm df-flex-col">
        <div class="df-flex-l align-center">
          <font-awesome-icon
            class="icon-size"
            icon="seedling"
            :color="colorIconNeutral"
          />
          <span>{{ item.cultivar }}</span>
        </div>
        <div class="df-flex-l align-center">
          <font-awesome-icon
            class="icon-size"
            icon="calendar"
            :color="colorIconNeutral"
          />
          <span>{{ item.data_semeadura.formatDate() }}</span>
        </div>
        <div class="df-flex-l align-center">
          <font-awesome-icon
            class="icon-size"
            icon="vector-square"
            :color="colorIconNeutral"
          />
          <span>{{ item.talhao }}</span>
        </div>
      </section>
    </section>
    <delete-modal
      v-if="isDeleteModalOpen"
      :action="deleteItem"
      :is-open="isDeleteModalOpen"
      :message="deleteMessage"
      @close="isDeleteModalOpen = false"
    />
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DeleteModal from '@/lib/Modal/DeleteModal.vue'
import '@/lib/components/Tooltip/DfTooltip.js'
import { logEvent, events } from '@/services/analytics'
import { getCropNameById } from '@/utils/crops'
import { isSowingInCurrentSeasons } from '@/utils/seasonsCrops'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SowingItemCard',

  components: {
    DeleteModal,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      colorBackgroundBlack: colors.colorBackgroundBlack,
      colorIconNeutral: colors.colorIconNeutral,
      isDeleteModalOpen: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    ...mapGetters('semeaduras', ['allSowings']),
    deleteMessage() {
      return this.$t('Modal.delete.sowing_delete_confirmation')
    },
    disabledClass() {
      return this.isInCurrentSeasons ? '' : 'disabled'
    },
    isInCurrentSeasons() {
      return isSowingInCurrentSeasons(this.item, this.seasonsCrops)
    },
    title() {
      return this.$t(getCropNameById(this.item.crop_id))
    },
  },

  methods: {
    ...mapActions('semeaduras', ['loadAllSowings']),
    async deleteItem() {
      this.deleteSowing()
      logEvent(events.homeModule['clickedButtonDeleteSowing'])
      this.isDeleteModalOpen = false
    },
    async deleteSowing() {
      try {
        await this.$api.semeaduras.delete(this.item.id)
        this.loadAllSowings(['status', 'colheita'])
      } catch (error) {
        console.error(error)
      }
    },
    openDeleteModal() {
      logEvent(events.homeModule.clickedButtonDeleteField)
      this.isDeleteModalOpen = true
    },
    redirectToSowingPage() {
      if (this.isInCurrentSeasons) {
        logEvent(events.clickedButtonSeeSowing)
        this.$router.push({
          path: '/execucao',
          query: {
            semeadura_id: this.item.id,
            fazenda_id: this.currentFarmId,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-card {
  height: 100%;
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  border-top: none;
  border-radius: $border-radius-sm;
  border: 1px solid $color-border;
  background-color: $color-background-white;
  cursor: pointer;

  &.disabled {
    cursor: auto;
  }
  @include d(m) {
    max-width: 100%;
  }
  .item-card__content {
    padding: $spacing-l;

    .content__actions {
      border: 1px solid $color-border;
      background-color: $color-background-white;
    }
    h1 {
      @include label-large;
      text-transform: capitalize;
    }
    .icon-size {
      height: 16px;
      width: 16px;
    }
    span {
      @include paragraph-medium;
      color: $color-text-neutral;
      text-transform: capitalize;
    }
  }
}
.actions__edit {
  width: 100%;
  padding: $spacing-m 0px;
  pointer-events: auto;

  &.v-btn--disabled {
    background-color: $color-background-disabled;
  }
}
::v-deep .v-tooltip__content {
  @include paragraph-x-small;
  border-radius: $border-radius-m !important;
  padding: $spacing-xs $spacing-sm !important;
}
.actions__exclude {
  width: 100%;
  margin: 0px !important;
  padding: $spacing-m 0px;
}
::v-deep .actions__edit.v-btn--disabled .v-btn__content {
  color: $color-text-disabled !important;
  background-color: $color-background-disabled !important;
}
::v-deep .actions__edit .v-btn__content,
::v-deep .actions__exclude .v-btn__content {
  @include label-small;
  background-color: $color-background-white;
  text-transform: capitalize;
  opacity: 1 !important;
}
::v-deep .actions__edit .v-btn__content {
  color: $color-text-neutral;
}
::v-deep .actions__exclude .v-btn__content {
  color: $color-text-danger;
}
</style>
