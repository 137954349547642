<template>
  <div
    class="df-flex-none df-flex-col item-card"
    :class="disabledClass"
    @click="redirectToFieldPage"
  >
    <section>
      <gmap
        data-id="enabled-map"
        v-if="item.enabled"
        class="item-card__map"
        disable-user-interaction
        :plot="item"
      />
      <div v-else class="map__disabled">
        <p>{{ $t('he_disabled') }}</p>
        <gmap class="item-card__map" disable-user-interaction :plot="item" />
      </div>
    </section>
    <section class="df-flex-l df-flex-col item-card__content">
      <section
        class="df-flex-none justify-space-between align-start flex-nowrap"
      >
        <h1 class="content__title">{{ title }}</h1>
        <v-menu offset-x>
          <template #activator="{ on }">
            <v-btn icon height="24px" width="24px" v-on="on">
              <font-awesome-icon color="#5B6459" icon="ellipsis-v" />
            </v-btn>
          </template>
          <v-card>
            <v-card-actions
              class="df-flex-none flex-column align-start content__actions pa-0"
            >
              <v-tooltip
                top
                :color="colorBackgroundBlack"
                :disabled="hasEditPermission"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="actions__edit pl-4 pr-6 py-3 rounded-0"
                    plain
                    text
                    :disabled="!hasEditPermission"
                    @click="redirectToFieldPage"
                  >
                    <span v-on="on" v-bind="attrs">
                      {{ $t('editar') }}
                    </span>
                  </v-btn>
                </template>
                <span>
                  {{ $t('exception.action_unauthorized') }}
                </span>
              </v-tooltip>
              <v-tooltip
                top
                :color="colorBackgroundBlack"
                :disabled="hasDeletePermission"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="actions__exclude pl-4 pr-6 py-3 ml-0 rounded-0"
                    plain
                    text
                    :disabled="!hasDeletePermission"
                    @click="openDeleteModal"
                  >
                    <span v-on="on" v-bind="attrs">
                      {{ $t('excluir') }}
                    </span>
                  </v-btn>
                </template>
                <span>
                  {{ $t('exception.action_unauthorized') }}
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-menu>
      </section>
      <section class="df-flex-sm df-flex-col">
        <div class="df-flex-l align-center">
          <font-awesome-icon
            class="icon-size"
            icon="seedling"
            :color="colorIconNeutral"
          />
          <span>
            {{ sowingByField.length }} {{ $t('Home.item_list.sowings') }}
          </span>
        </div>
      </section>
    </section>
    <delete-modal
      v-if="isDeleteModalOpen"
      :action="deleteItem"
      :is-open="isDeleteModalOpen"
      :message="deleteMessage"
      @close="isDeleteModalOpen = false"
    />
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DeleteModal from '@/lib/Modal/DeleteModal.vue'
import Gmap from '@/modules/plot/components/Maps/Gmap.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FieldItemCard',

  components: {
    DeleteModal,
    Gmap,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      colorBackgroundBlack: colors.colorBackgroundBlack,
      colorIconNeutral: colors.colorIconNeutral,
      isDeleteModalOpen: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('permissions', ['hasPermission']),
    ...mapGetters('semeaduras', ['allSowings']),
    deleteMessage() {
      return this.$t('Modal.delete.field_delete_confirmation')
    },
    disabledClass() {
      return this.hasEditPermission ? '' : 'disabled'
    },
    hasDeletePermission() {
      return this.hasPermission(this.$p.plot.delete)
    },
    hasEditPermission() {
      return this.hasPermission(this.$p.plot.edit)
    },
    sowingByField() {
      const sowings = this.allSowings.filter(
        (sowing) => sowing.talhao_id == this.item.id && !sowing.colheita
      )
      return sowings
    },
    title() {
      return this.item.nome
    },
  },

  methods: {
    ...mapActions('plot', ['deletePlot']),
    async deleteItem() {
      this.deletePlot(this.item.id)
      logEvent(events.homeModule.clickedButtonDeleteField)
      this.isDeleteModalOpen = false
    },
    openDeleteModal() {
      logEvent(events.homeModule.clickedButtonDeleteField)
      this.isDeleteModalOpen = true
    },
    redirectToFieldPage() {
      if (this.hasEditPermission) {
        logEvent(events.homeModule.clickedButtonEditField)
        this.$router.push({
          path: '/plot',
          query: {
            plot_id: this.item.id,
            fazenda_id: this.currentFarmId,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-card {
  height: 100%;
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  border-top: none;
  border-radius: $border-radius-sm;
  border: 1px solid $color-border;
  background-color: $color-background-white;
  cursor: pointer;

  @include d(m) {
    max-width: 100%;
  }
  .map__disabled {
    position: relative;

    p {
      @include heading-small;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    .vue-map-container {
      background-color: $color-background-disabled;
      opacity: 0.4;
    }
  }
  .item-card__map {
    height: 123px !important;

    @include d(m) {
      width: 100%;
    }
  }
  .item-card__content {
    flex: 1;
    border: 1px solid $color-border;
    padding: $spacing-l;

    .content__title {
      min-width: 0;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    }
    .content__actions {
      border: 1px solid $color-border;
      background-color: $color-background-white;
    }
    h1 {
      @include label-large;
      text-transform: capitalize;
    }
    .icon-size {
      height: 16px;
      width: 16px;
    }
    span {
      @include paragraph-medium;
      color: $color-text-neutral;
      text-transform: capitalize;
    }
  }
}
.actions__edit,
.actions__exclude {
  width: 100%;
  margin: 0px !important;
  padding: $spacing-m 0px;
  pointer-events: auto;

  &.v-btn--disabled {
    background-color: $color-background-disabled;
    cursor: auto;
  }
}
::v-deep .v-tooltip__content {
  @include paragraph-x-small;
  border-radius: $border-radius-m !important;
  padding: $spacing-xs $spacing-sm !important;
}
::v-deep .actions__edit.v-btn--disabled .v-btn__content,
::v-deep .actions__exclude.v-btn--disabled .v-btn__content {
  color: $color-text-disabled !important;
  background-color: $color-background-disabled !important;
}
::v-deep .vue-map {
  border-radius: $spacing-xs $spacing-xs 0px 0px;
}
::v-deep .actions__edit .v-btn__content,
::v-deep .actions__exclude .v-btn__content {
  @include label-small;
  background-color: $color-background-white;
  text-transform: capitalize;
  opacity: 1 !important;
}
::v-deep .actions__edit .v-btn__content {
  color: $color-text-neutral;
}
::v-deep .actions__exclude .v-btn__content {
  color: $color-text-danger;
}
::v-deep .vue-map > :nth-child(2) {
  display: none;
}
</style>
