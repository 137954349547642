<template>
  <div v-if="currentFarm" class="home-header">
    <df-page-header>
      <template #title-section>
        <div class="df-flex-m df-flex-col">
          <v-chip
            v-if="!currentFarm.enabled"
            class="header__chip"
            small
            :color="colorBackgroundNeutral"
          >
            {{ $t('she_disabled') }}
          </v-chip>
          <df-header-title
            :subtitle="$t('Home.header.hello_user', [currentUser.name])"
            :title="currentFarm.name"
          />
        </div>
      </template>
      <template #infos>
        <div class="df-flex-l header__infos">
          <div class="df-flex-sm align-baseline">
            <font-awesome-icon
              icon="map-marker-alt"
              size="xs"
              :color="colorIconSecondary"
            />
            <div class="d-flex df-flex-col">
              <h3 class="info__header">{{ $t('localization') }}</h3>
              <p class="info__text">
                {{ currentFarm.location.city.name }} -
                {{ currentFarm.location.state.acronym }}
              </p>
            </div>
          </div>
          <div class="df-flex-sm align-baseline">
            <font-awesome-icon
              icon="vector-square"
              size="xs"
              :color="colorIconSecondary"
            />
            <div class="d-flex df-flex-col">
              <h3 class="info__header">{{ $t('Home.header.sown_area') }}</h3>
              <p class="info__text">
                {{ area }}
                {{ $unitMeasures.area[$currentLanguage()] }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <df-tooltip
          v-if="currentFarm.enabled"
          :hide="hasEditFarmPermission"
          :text="$t('exception.action_unauthorized')"
        >
          <df-button
            variant="text-green"
            :disabled="!hasEditFarmPermission"
            @click="openModalFarms"
          >
            {{ $t('Home.header.edit_farm') }}
          </df-button>
        </df-tooltip>
        <df-button
          v-else
          icon="toggle-on"
          variant="blurred"
          @click="showEnableConfirmation"
        >
          {{ $t('Home.header.enable_farm') }}
        </df-button>
      </template>
    </df-page-header>
    <modal-fazenda
      v-if="dialogModalFarm"
      :dialog="dialogModalFarm"
      :farm-id="currentFarmId"
      @close="reloadFarmsList"
    />
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfHeaderTitle from '@/lib/components/Header/DfHeaderTitle.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import '@/lib/components/Tooltip/DfTooltip.js'
import { convertToTargetUnit } from '@/components/Form/converter'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeHeader',

  data() {
    return {
      colorBackgroundNeutral: colors.colorBackgroundNeutral,
      colorIconSecondary: colors.colorIconSecondary,
      dialogModalFarm: false,
    }
  },

  components: {
    DfButton,
    DfHeaderTitle,
    DfPageHeader,
    ModalFazenda,
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
    ...mapGetters('permissions', ['hasPermission']),
    ...mapGetters('user', ['currentUser']),
    area() {
      return convertToTargetUnit(this.currentFarm.total_area, 'area')
    },
    hasEditFarmPermission() {
      return this.hasPermission(this.$p.farm.edit)
    },
  },

  methods: {
    ...mapActions('farms', ['fetchFarms', 'enableDisableFarm']),
    openModalFarms() {
      this.dialogModalFarm = true
    },
    async reloadFarmsList() {
      this.dialogModalFarm = false
      await this.fetchFarms(this.$route?.query?.fazenda_id)
    },
    async showEnableConfirmation() {
      await this.enableDisableFarm({
        farmId: this.currentFarmId,
      })

      this.$root.$emit('notify', 'success', this.$t('fazenda.enable.success'))
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.home-header {
  .header__chip {
    color: $color-text-white;
    width: fit-content;
  }
  .header__infos {
    flex: 0 0 auto;

    .info__header {
      @include label-x-small;
      color: $color-text-secondary;
      font-weight: 700;
      text-transform: uppercase;
    }
    .info__text {
      @include paragraph-medium;
      color: $color-text-neutral;
    }
  }
}
</style>
